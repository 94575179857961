<template>
  <div class="container-xxl gx-5">
    <SingularTaskLayout
      :title="$t('document.documentsOverview.classificationTasks.pageTitle')"
      :task="currentTask || {}"
      :is-loading-task="isLoadingTasks"
      :validate-task-before-complete-hook="validateTaskBeforeCompleteHook"
      :should-disable-ignore-warnings="shouldDisableIgnoreWarnings"
      @complete-task="refetchAllTasks"
      @skip-task="onSkipTask"
    >
      <template slot="pagination">
        <el-pagination
          small
          background
          layout="prev, pager, next, jumper"
          class="d-flex align-items-center"
          :class="$direction === 'rtl' ? 'text-left' : 'text-right'"
          :current-page="taskNumber"
          :page-size="1"
          :total="tasksCount"
          @current-change="onUpdateTaskNumber"
        />
      </template>

      <template slot="menu-dropdown-items">
        <el-dropdown-item>
          <div class="text-danger" @click="removeDocument">
            <TrashCanIcon width="24px" height="24px" />
            <span class="mx-1">
              {{ $t('document.documentsOverview.classificationTasks.menuOptions.removeDocument') }}
            </span>
          </div>
        </el-dropdown-item>
      </template>
      <template #content>
        <div v-if="isNewDesign" class="panels-container">
          <div class="viewer-wrapper ms-5">
            <div v-if="isLoadingDocument || isLoadingTasks">
              <ClassificationTaskDocumentViewerSkeleton />
            </div>
            <div v-else>
              <div v-if="currentDoc?.filePathUrl">
                <file-viewer
                  class="viewer-wrapper"
                  :url="`${currentDoc?.filePathUrl}#navpanes=0&scrollbar=0&view=Fit`"
                />
              </div>
            </div>
          </div>

          <div class="options-wrapper" @scroll="handleScroll">
            <ClassificationTaskOptionsPanel
              ref="optionsPanelRef"
              :document="currentDoc"
              :templates="sortedTemplates"
              :is-supplier-and-doc-type-selected="isSupplierAndDocTypeSelected"
              :is-loading-templates-panel="isLoadingTasks || isLoadingDocument || isLoadingTemplate"
              :is-loading-options="isLoadingDocument || isLoadingTasks || isLoadingSuppliers"
              :selected-template="selectedTemplate"
              :has-locale-error-flag="hasLocaleErrorFlag"
              :suppliers="suppliers"
              :username="username"
              :documents-types="documentsTypes"
              :has-declaration-error-flag="hasDeclarationErrorFlag"
              :has-supplier-error-flag="hasSupplierErrorFlag"
              :has-type-error-flag="hasTypeErrorFlag"
              :already-exists-document-with-document-number="alreadyExistsDocumentWithDocumentNumber"
              :task="currentTask || {}"
              @on-change-template="onChangeTemplate"
              @locale-changed="onLocaleChanged"
              @is-manual-changed="onIsManualChanged"
              @on-supplier-changed="onSupplierChanged"
              @on-document-type-changed="onDocumentTypeChanged"
              @on-document-number-changed="onDocumentNumberChanged"
              @on-has-declaration-changed="onHasDeclarationChanged"
              @on-toggle-replicate-of="onToggleReplicateOf"
              @on-template-structure-params-toggle="onTemplateStructureParamsToggle"
              @on-filter-template-question-presented="onFilterTemplateQuestionPresented"
              @on-filter-template-question-answered="onFilterTemplateQuestionAnswered"
              @on-filter-template-question-answer-removed="onFilterTemplateQuestionAnswerRemoved"
            />
          </div>
        </div>
        <div v-else class="panels-container">
          <div class="panel-wrapper panel-sticky">
            <ClassificationTaskDocumentPanel
              :is-loading="isLoadingDocument || isLoadingTasks"
              :document="currentDoc"
              :suppliers="suppliers"
              :username="username"
              :documents-types="documentsTypes"
              :has-declaration-error-flag="hasDeclarationErrorFlag"
              :has-supplier-error-flag="hasSupplierErrorFlag"
              :has-type-error-flag="hasTypeErrorFlag"
              :already-exists-document-with-document-number="alreadyExistsDocumentWithDocumentNumber"
              @on-supplier-changed="onSupplierChanged"
              @on-document-type-changed="onDocumentTypeChanged"
              @on-document-number-changed="onDocumentNumberChanged"
              @on-has-declaration-changed="onHasDeclarationChanged"
              @on-toggle-replicate-of="onToggleReplicateOf"
            />
          </div>
          <div class="placeholder bg-secondary divider"></div>
          <div class="panel-wrapper">
            <ClassificationTaskTemplatesPanel
              :templates="sortedTemplates"
              :is-supplier-and-doc-type-selected="isSupplierAndDocTypeSelected"
              :is-loading="isLoadingTasks || isLoadingDocument || isLoadingTemplate"
              :selected-template="selectedTemplate"
              :has-locale-error-flag="hasLocaleErrorFlag"
              @on-change-template="onChangeTemplate"
              @locale-changed="onLocaleChanged"
              @is-manual-changed="onIsManualChanged"
            />
          </div>
        </div>
      </template>
    </SingularTaskLayout>
  </div>
</template>

<script>
import { isNil, isEmpty, anyPass, reject } from 'ramda';
import { ref, watch, computed, getCurrentInstance } from 'vue';
import { DateTime } from 'luxon';
import { TrashCanIcon } from '@/assets/icons';
import { SingularTaskLayout } from '../tasks/task/components';
import { DOCUMENT_TYPES, CONSOLIDATED_DOCS } from '@/modules/document/types';
import { useTenancy } from '@/modules/auth';
import { DOCUMENT_VALIDATE_CLASSIFICATION_QUERY } from '@/modules/document/compositions/validation';
import { updateDocument, deleteDocument } from '@/modules/document/compositions/documents-operations';
import {
  useDocumentTasks,
  useDocumentNew2,
  useDocumentsAggregationByStructureToken,
  useDocumentsByDocumentNumber,
} from '@/modules/document/compositions';
import {
  ClassificationTaskDocumentPanel,
  ClassificationTaskTemplatesPanel,
  ClassificationTaskOptionsPanel,
} from '@/modules/document/components';
import ClassificationTaskDocumentViewerSkeleton from './components/ClassificationTaskDocumentViewerSkeleton';
import { FileViewer } from '@clarityo/ui-components';
import { useSuppliersNew } from '../business/compositions';
import { useUsers } from '../payment/compositions';
import { useTemplates } from '@/modules/automation';
import { useNotification } from '@/modules/core';
import { generateSharedDebounce } from '../../imports/utils';
import { useRoute } from 'vue-router/composables';
// Analytics
import { ANALYTICS_ENUMS } from './utils/analytics';
import { ANALYTICS_EVENT_TYPES } from '@/analytics/types';
import { useAnalytics } from '@/analytics/compositions/useAnalytics';

const { debounce } = generateSharedDebounce();

export default {
  meteor: {},
  components: {
    TrashCanIcon,
    ClassificationTaskTemplatesPanel,
    ClassificationTaskDocumentPanel,
    FileViewer,
    ClassificationTaskDocumentViewerSkeleton,
    ClassificationTaskOptionsPanel,
    SingularTaskLayout,
  },

  setup() {
    const { error } = useNotification();
    const root = getCurrentInstance().proxy;
    const shouldDisableIgnoreWarnings = ref(true);
    const supplierId = ref(undefined);
    const documentNumber = ref(undefined);
    const documentType = ref(undefined);
    const hasDeclaration = ref(undefined);
    const replicateOf = ref(undefined);
    const nonTemplateIsManual = ref(undefined);
    const nonTemplateLocale = ref(undefined);
    const selectedTemplate = ref(undefined);
    const templateStructureParams = ref(undefined);
    const route = useRoute();
    const { logEvent } = useAnalytics();
    const optionsPanelRef = ref(null);

    const { currentTenantId } = useTenancy();
    const {
      documentTasks,
      loading: isLoadingTasks,
      refetch: refetchTasks,
    } = useDocumentTasks({
      type: 'upload',
      businessId: currentTenantId.value,
    });

    const unClassifiedTasks = computed(() => [...documentTasks.value]);

    const { aggregation: aggregationDocuments } = useDocumentsAggregationByStructureToken(
      computed(() => ({
        businessId: currentTenantId.value,
        fromDate: DateTime.utc().minus({ months: 3 }).toString().split('T')[0],
        recorded: true,
        groupBy: 'structureToken',
      }))
    );

    const { suppliers, loading: isLoadingSuppliers } = useSuppliersNew();
    const businessId = computed(() => currentTenantId.value);

    const taskNumber = ref(Number(root.$route.query.taskNumber));
    const taskIndex = computed(() => (Number.isNaN(taskNumber.value) ? 1 : taskNumber.value) - 1);
    const currentTask = computed(() => unClassifiedTasks.value[taskIndex.value]);
    const tasksCount = computed(() => unClassifiedTasks.value.length);

    const documentId = ref(undefined);

    const { document, loading: isLoadingDocument, onError: onErrorFetchDocument } = useDocumentNew2(documentId);

    onErrorFetchDocument(() =>
      error(root.$i18n.t('document.documentsOverview.classificationTasks.errors.failedToFetchDocument'))
    );

    const { users } = useUsers(
      computed(() =>
        !document?.value ? [] : [document.value].filter((doc) => doc.createdBy !== null).map((doc) => doc.createdBy)
      )
    );

    function getHasDeclarationDefault(documentType) {
      return TYPES_WITH_HARD_CODED_DECLARATION.includes(documentType) ? false : null;
    }

    const username = computed(
      () => currentDoc?.value?.fromEmail || getUserName(currentDoc?.value?.createdBy, [...Object.values(users.value)])
    );

    function getUserName(userId, users) {
      const user = users?.find((u) => u.id === userId);
      if (!user || (!user.firstName && !user.lastName)) return 'Unknown user';

      return `${user.firstName} ${user.lastName}`;
    }

    const isSupplierAndDocTypeSelected = computed(
      () => !!((supplierId?.value && documentType?.value) || documentType?.value === DOCUMENT_TYPES.OTHER)
    );

    const { templates, loading: isLoadingTemplate } = useTemplates(
      computed(() => ({
        supplierId: supplierId,
        documentType: documentType,
        signedUrl: true,
        isArchived: false,
      })),
      computed(() => ({ enabled: !!(supplierId?.value && documentType?.value) }))
    );

    const sortedTemplates = computed(() => {
      if (!supplierId.value) {
        // Handle cases where we didn't re-fetch the templates list after removing the supplier following a previous selection.
        return [];
      }

      if (!aggregationDocuments.value) return [];

      const mapTokenToCount = aggregationDocuments.value.reduce((acc, c) => {
        acc[c.structureToken] = c.count;
        return acc;
      }, {});

      const sorted = [...templates.value].sort((t1, t2) => {
        return (mapTokenToCount[t1.structureToken] || 0) < (mapTokenToCount[t2.structureToken] || 0) ? 1 : -1;
      });

      return sorted;
    });

    const templateIsManual = computed(() => {
      const selectedTemplateId = selectedTemplate.value;

      if (!selectedTemplateId) {
        return nonTemplateIsManual.value;
      }

      const template = templates.value.find((t) => t.id === selectedTemplateId);
      return template?.isManual || false;
    });

    const documentLocale = computed(() => {
      const selectedTemplateId = selectedTemplate.value;

      if (!selectedTemplateId) {
        return nonTemplateLocale.value;
      }

      const template = templates.value.find((t) => t.id === selectedTemplateId) || {};
      return template.locale;
    });

    // update currentDoc (state) with updated data:
    const currentDoc = computed(() => ({
      ...document.value,
      supplierId: supplierId.value ?? document.supplierId,
      documentNumber: documentNumber.value ?? document.documentNumber,
      type: documentType.value ?? document.type,
      hasDeclaration: hasDeclaration.value,
      replicateOf: replicateOf.value ?? document.replicateOf,
      isManual: templateIsManual.value ?? document.isManual ?? false,
      locale: documentLocale.value ?? document.locale,
      templateId: selectedTemplate.value ?? document.type,
      structureParams: templateStructureParams.value ?? document.structureParams,
    }));

    const saveDocument = async () => {
      shouldDisableIgnoreWarnings.value = true;

      const {
        id: documentId,
        isManual,
        locale,
        hasDeclaration,
        supplierId,
        type,
        documentNumber,
        templateId,
        structureParams,
        replicateOf,
      } = currentDoc.value;

      const data = {
        isManual: !!isManual,
        locale,
        hasDeclaration,
        supplierId,
        type,
        documentNumber,
        templateId,
        replicateOf,
        structureParams: removeTypeNameAndNulls(structureParams),
      };
      const rejectNonValue = reject(anyPass([isNil, isEmpty]));
      const cleanedData = rejectNonValue(data);

      updateDocument({
        id: documentId,
        data: cleanedData,
      }).catch((err) => {
        error({ title: err.error, message: err.reason });
      });
    };

    const TYPES_WITH_HARD_CODED_DECLARATION = [
      DOCUMENT_TYPES.RECONCILIATION_STATEMENT,
      DOCUMENT_TYPES.AGED_DEBTORS_REPORT,
      DOCUMENT_TYPES.OTHER,
    ];

    const { documents: documentByDocumentNumber } = useDocumentsByDocumentNumber(
      computed(() => ({
        businessId: businessId.value,
        documentNumber: documentNumber.value,
        supplierId: supplierId.value,
        types: [documentType.value],
      }))
    );

    const alreadyExistsDocumentWithDocumentNumber = computed(() => {
      if (!documentNumber.value) {
        return {};
      }

      const documents = documentByDocumentNumber.value;
      const documentsWithoutReplications = documents.filter((doc) => !doc.replicateOf);
      const documentsWithSameType = documentsWithoutReplications.filter((doc) => doc.type === currentDoc.type);
      return documentsWithSameType[0] || documentsWithoutReplications[0];
    });

    const hasSupplierErrorFlag = computed(() => {
      return (
        isDocumentValidated.value && !currentDoc.value?.supplierId && currentDoc.value?.type !== DOCUMENT_TYPES.OTHER
      );
    });

    const hasTypeErrorFlag = computed(() => {
      return isDocumentValidated.value && !currentDoc.value?.type;
    });

    const hasDeclarationErrorFlag = computed(() => {
      return isDocumentValidated.value && typeof currentDoc.value.hasDeclaration !== 'boolean';
    });

    const hasLocaleErrorFlag = computed(() => {
      const selectedTemplateId = selectedTemplate.value;
      return isDocumentValidated.value && !selectedTemplateId && isNil(nonTemplateLocale.value);
    });

    const onSupplierChanged = (value) => {
      supplierId.value = value;
      selectedTemplate.value = undefined;
      replicateOf.value = null;

      saveDocument();
    };

    const onDocumentTypeChanged = (value) => {
      documentType.value = value;
      hasDeclaration.value = getHasDeclarationDefault(value);
      nonTemplateLocale.value = undefined;
      selectedTemplate.value = undefined;
      replicateOf.value = null;

      saveDocument();
    };

    const debounceSaveDocument = debounce(saveDocument, 200);
    const onDocumentNumberChanged = (value) => {
      documentNumber.value = value;
      // TODO: do we need it?
      replicateOf.value = null;

      debounceSaveDocument();
    };

    const onToggleReplicateOf = () => {
      replicateOf.value = currentDoc.value.replicateOf ? null : alreadyExistsDocumentWithDocumentNumber.value.id;
      saveDocument();
    };

    const onHasDeclarationChanged = (value) => {
      hasDeclaration.value = value;
      saveDocument();
    };

    const documentsTypes = Object.values(DOCUMENT_TYPES).filter((t) => !CONSOLIDATED_DOCS.includes(t));

    const onChangeTemplate = (templateId) => {
      selectedTemplate.value = templateId ?? undefined;
      const template = templates.value.find((t) => t.id === templateId) || {};
      templateStructureParams.value = template?.structureParams;

      saveDocument();
    };

    function removeTypeNameAndNulls(item) {
      if (typeof item !== 'object' || item === null) return item;

      const cleanedSP = {};
      for (const [key, value] of Object.entries(item)) {
        if (key === '__typename' || value == null) {
          // removed
        } else if (typeof value === 'object' && value !== null) {
          cleanedSP[key] = removeTypeNameAndNulls(value);
        } else {
          cleanedSP[key] = value;
        }
      }
      return cleanedSP;
    }

    const onLocaleChanged = (localeInput) => {
      nonTemplateLocale.value = localeInput;
      saveDocument();
    };

    const onIsManualChanged = (isManualInput) => {
      nonTemplateIsManual.value = isManualInput ?? false;
      saveDocument();
    };

    const isDocumentValidated = ref(false);

    const getWarningMessages = (validations = {}) => {
      const warnings = [];
      if (!validations.business) {
        warnings.push(root.$i18n.t(`document.documentsOverview.classificationTasks.alerts.businessAlert`));
      }

      if (!validations.supplier) {
        warnings.push(root.$i18n.t(`document.documentsOverview.classificationTasks.alerts.supplierAlert`));
      }

      if (!validations.documentType) {
        warnings.push(root.$i18n.t(`document.documentsOverview.classificationTasks.alerts.documentTypeAlert`));
      }

      return warnings;
    };

    const getErrorsMessages = () => {
      const errors = [];

      if (hasSupplierErrorFlag.value) {
        errors.push(root.$i18n.t('document.documentsOverview.classificationTasks.document.validations.supplier'));
      }
      if (hasTypeErrorFlag.value) {
        errors.push(root.$i18n.t('document.documentsOverview.classificationTasks.document.validations.type'));
      }
      if (hasDeclarationErrorFlag.value) {
        errors.push(root.$i18n.t('document.documentsOverview.classificationTasks.document.validations.hasDeclaration'));
      }
      if (hasLocaleErrorFlag.value) {
        errors.push(root.$i18n.t('document.documentsOverview.classificationTasks.document.validations.locale'));
      }

      return errors;
    };

    const validateTaskBeforeCompleteHook = async () => {
      isDocumentValidated.value = true;
      const errors = getErrorsMessages();
      if (errors.length) {
        logEvent(ANALYTICS_EVENT_TYPES.TASKS.VALIDATION_ERRORS, {
          task_id: currentTask.value.id,
          source_page: ANALYTICS_ENUMS.SOURCE_PAGE.CLASSIFICATION,
          source_url: route?.fullPath,
          page_section: ANALYTICS_ENUMS.PAGE_SECTION.HEADER,
          outcome_type: ANALYTICS_ENUMS.OUTCOME_TYPE.ERROR,
          outcome_details: JSON.stringify(errors),
        });

        return { errors, warnings: [] };
      }

      const validations = await root.$apollo.query({
        query: DOCUMENT_VALIDATE_CLASSIFICATION_QUERY,
        variables: { documentId: currentDoc?.value?.id },
      });

      shouldDisableIgnoreWarnings.value = false;
      const warnings = getWarningMessages(validations.data.documentValidateClassification);

      logEvent(ANALYTICS_EVENT_TYPES.TASKS.VALIDATION_WARNINGS, {
        task_id: currentTask.value.id,
        source_page: ANALYTICS_ENUMS.SOURCE_PAGE.CLASSIFICATION,
        source_url: route?.fullPath,
        page_section: ANALYTICS_ENUMS.PAGE_SECTION.HEADER,
        outcome_type: isEmpty(warnings) ? ANALYTICS_ENUMS.OUTCOME_TYPE.SUCCESS : ANALYTICS_ENUMS.OUTCOME_TYPE.WARNING,
        outcome_details: isEmpty(warnings) ? null : JSON.stringify(warnings),
      });

      return { warnings, errors: [] };
    };

    const onUpdateTaskNumber = (value) => {
      if (value <= tasksCount.value) {
        root.$router.replace({ query: { taskNumber: value } });
        taskNumber.value = value;
      }
    };

    watch(document, (newDocument) => {
      // clear default when document refetch.
      supplierId.value = newDocument.supplierId;
      documentNumber.value = newDocument.documentNumber;
      documentType.value = newDocument.type;
      replicateOf.value = newDocument.replicateOf;
      hasDeclaration.value = getHasDeclarationDefault(newDocument.type);
      selectedTemplate.value = newDocument.templateId;
      templateStructureParams.value = newDocument.structureParams;
      isDocumentValidated.value = false;
      shouldDisableIgnoreWarnings.value = true;
    });

    watch(currentTask, (newTask, oldTask) => {
      // originally I used computed to calculate documentId, but avoid it for cases where the currentTask refetch when updating managerReview comment
      if (newTask?.data?.documentId !== oldTask?.data?.documentId) {
        documentId.value = newTask?.data?.documentId;
      }
    });

    const refetchAllTasks = async () => {
      isDocumentValidated.value = false;

      logEvent(ANALYTICS_EVENT_TYPES.TASKS.COMPLETED, {
        task_id: currentTask.value.id,
        source_page: ANALYTICS_ENUMS.SOURCE_PAGE.CLASSIFICATION,
        source_url: route?.fullPath,
        page_section: ANALYTICS_ENUMS.PAGE_SECTION.HEADER,
        outcome_type: ANALYTICS_ENUMS.OUTCOME_TYPE.SUCCESS,
        outcome_details: JSON.stringify({ templatesCount: sortedTemplates.value.length ?? 0 }),
      });

      await refetchTasks().catch((err) => {
        error({ title: err.error, message: err.reason });
      });

      // update taskNumber when completed last item
      if (tasksCount.value < taskNumber.value) {
        onUpdateTaskNumber(tasksCount.value);
      }
    };

    const removeDocument = () => {
      root
        .$confirm(
          root.$i18n.t('document.documentsOverview.classificationTasks.menuOptions.removeDialog.message'),
          root.$i18n.t('document.documentsOverview.classificationTasks.menuOptions.removeDialog.title'),
          {
            confirmButtonText: root.$i18n.t(
              'document.documentsOverview.classificationTasks.menuOptions.removeDialog.confirm'
            ),
            cancelButtonText: root.$i18n.t(
              'document.documentsOverview.classificationTasks.menuOptions.removeDialog.cancel'
            ),
            type: 'warning',
          }
        )
        .then(() => {
          deleteDocument({ id: currentDoc.value.id })
            .then(async () => {
              root.$message.success(root.$i18n.tc('document.documentsOverview.classificationTasks.message.removed'));
              await new Promise((resolve) => setTimeout(resolve, 1500)); // make sure task is deleted along with document
              await refetchAllTasks();
            })
            .catch((err) => {
              const message = err.error ? `${err.error} - ${err.reason}` : err.message ?? err;
              root.$message.error(message);
            });
        })
        .catch(() => {
          // cancel
        });
    };

    const onTemplateStructureParamsToggle = () => {
      logEvent(ANALYTICS_EVENT_TYPES.DOC.TEMPLATE_STRUCTURE_PARAMS_CLICKED, {
        task_id: currentTask.value.id,
        source_page: ANALYTICS_ENUMS.SOURCE_PAGE.CLASSIFICATION,
        source_url: route?.fullPath,
        page_section: ANALYTICS_ENUMS.PAGE_SECTION.BODY,
        outcome_type: '',
        outcome_details: '',
      });
    };

    const handleScroll = () => {
      if (optionsPanelRef.value) optionsPanelRef.value.handleScroll();
    };

    const onFilterTemplateQuestionPresented = (question) => {
      logEvent(ANALYTICS_EVENT_TYPES.DOC.TEMPLATE_FILTER_QUESTION_PRESENTED, {
        task_id: currentTask.value.id,
        source_page: ANALYTICS_ENUMS.SOURCE_PAGE.CLASSIFICATION,
        source_url: route?.fullPath,
        page_section: ANALYTICS_ENUMS.PAGE_SECTION.BODY,
        outcome_type: '',
        outcome_details: question,
      });
    };

    const onFilterTemplateQuestionAnswered = (answer) => {
      logEvent(ANALYTICS_EVENT_TYPES.DOC.TEMPLATE_FILTER_QUESTION_ANSWERED, {
        task_id: currentTask.value.id,
        source_page: ANALYTICS_ENUMS.SOURCE_PAGE.CLASSIFICATION,
        source_url: route?.fullPath,
        page_section: ANALYTICS_ENUMS.PAGE_SECTION.BODY,
        outcome_type: '',
        outcome_details: answer,
      });
    };

    const onFilterTemplateQuestionAnswerRemoved = (removedAnswers) => {
      logEvent(ANALYTICS_EVENT_TYPES.DOC.TEMPLATE_FILTER_QUESTION_ANSWER_REMOVED, {
        task_id: currentTask.value.id,
        source_page: ANALYTICS_ENUMS.SOURCE_PAGE.CLASSIFICATION,
        source_url: route?.fullPath,
        page_section: ANALYTICS_ENUMS.PAGE_SECTION.BODY,
        outcome_type: '',
        outcome_details: removedAnswers,
      });
    };

    return {
      removeDocument,
      hasSupplierErrorFlag,
      hasTypeErrorFlag,
      hasDeclarationErrorFlag,
      hasLocaleErrorFlag,
      onUpdateTaskNumber,
      validateTaskBeforeCompleteHook,
      onSkipTask: async (taskId) => {
        await refetchAllTasks();
        if (currentTask.value?.id === taskId) {
          onUpdateTaskNumber(taskNumber.value + 1);
        }
      },
      refetchAllTasks,
      isLoadingDocument,
      isLoadingSuppliers,
      onSupplierChanged,
      onDocumentTypeChanged,
      onDocumentNumberChanged,
      onHasDeclarationChanged,
      onToggleReplicateOf,
      taskNumber,
      currentTask,
      documentsTypes,
      username,
      tasksCount,
      isSupplierAndDocTypeSelected,
      alreadyExistsDocumentWithDocumentNumber,
      sortedTemplates,
      isLoadingTemplate,
      isLoadingTasks,
      currentDoc,
      suppliers: computed(() => [...suppliers.value].sort((a, b) => a.name.localeCompare(b.name))),
      onChangeTemplate,
      selectedTemplate,
      onLocaleChanged,
      onIsManualChanged,
      shouldDisableIgnoreWarnings,
      onTemplateStructureParamsToggle,
      optionsPanelRef,
      handleScroll,
      onFilterTemplateQuestionPresented,
      onFilterTemplateQuestionAnswered,
      onFilterTemplateQuestionAnswerRemoved,
      isNewDesign: true,
    };
  },
  data() {
    return {
      showReviewEditButton: false,
      commentReviewModalOpen: false,
      selectedCommentTask: null,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@/stylesheets/scss/global';
@import '@/modules/document/components/document-global.scss';

.divider {
  background-color: $outline;
  width: 1px;
  display: flex;
  grid-row: 0;
  padding: 0px;
  margin: 0 2.5rem;
}

.panel-wrapper {
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  flex-grow: 1;
  width: 100%;
  height: 100vh;
}

::v-deep .panels-container {
  overflow: inherit;
  height: $content-height;
  width: 100%;
  display: flex;
  justify-content: center;
}

.options-wrapper {
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  flex-grow: 2;
  width: 100%;
  height: 100%;
}

.review-required-comment-alert {
  display: flex;
  flex-direction: row;
  background-color: #f9f2e8;
  padding: 16px;
  border-radius: 4px;
  height: 52px;
  color: #b85c00;
  font-size: small;
}

.edit-icon {
  cursor: pointer;
}

.review-required-comment-alert > div {
  margin-right: 10px;
}

.panel-sticky {
  position: sticky;
  top: 0;
}

.container-xxl {
  width: 100%;
  padding: 0 1.5rem;
  max-width: unset;
  margin: 0 auto;
}

.viewer-wrapper {
  height: 100%;
  width: auto;
  aspect-ratio: $a4-ratio-viewer;
}

.viewer-wrapper iframe {
  width: 100%;
  height: 100%;
}
</style>
